import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import EthDiamondPlain from '../../../static/eth-diamond-plain.svg';
import EthRound from '../../../static/eth-round-landing.svg';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import VerifyDepositsAllowance from './VerifyDepositsAllowance';
import { Link } from '../../../components/Link';

const Container = styled.div`
  background: ${p => p.theme.white};
`;
const SubContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.largest}) {
    padding: 0 60px;
  }
`;
const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    display: none;
  }
`;

// adds an opaque eth logo behind the text on small screen sizes
const ContentContainer = styled.div`
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    :before {
      content: ' ';
      display: block;
      position: absolute;
      right: 60px;
      top: 35px;
      width: 250px;
      height: 400px;
      z-index: 1;
      opacity: 0.15;
      background-image: url(${EthDiamondPlain});
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
`;
export const Introduction = (): JSX.Element => {
  return (
    <Container>
      <SubContainer className="py100 flex">
        <ImgContainer>
          <img src={EthRound} alt="" />
        </ImgContainer>
        <ContentContainer>
          <Heading level={2} size="medium" color="blueDark" margin="none">
            <FormattedMessage defaultMessage="Validators and LUKSO" />
          </Heading>
          <Text className="mt20">
            <FormattedMessage
              defaultMessage="Testnet validators need to be whitelisted to ensure the longevity and sustainability
                of the LUKSO Testnet. If you intend to run the Testnet nodes indefinitely and want to be whitelisted,
                feel free to reach out to {email}"
              values={{
                email: (
                  <Link to="mailto:testnet-validators@lukso.network">
                    testnet-validators@lukso.network
                  </Link>
                ),
              }}
            />
          </Text>

          <Text className="mt20">
            <FormattedMessage defaultMessage="By running a validator, you'll be responsible for securing the Testnet. Please note that LYXt are Testnet tokens and have no value!" />
          </Text>
          {/* NOTE: this section felt like there was a lot of repetition with the section below so have reframed to talk about validators in the context of the upgrades */}
        </ContentContainer>
      </SubContainer>
      <VerifyDepositsAllowance />
    </Container>
  );
};
