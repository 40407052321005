import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '../../../components/Text';
import { Heading } from '../../../components/Heading';
import { CONTRACT_ADDRESS, INFURA_URL } from '../../../utils/envVars';
import { contractAbi } from '../../../contractAbi';
import Error from '../../../static/custom/error.svg';
import Success from '../../../static/custom/complete-filled.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
`;

const Message = styled(Text)`
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 24px;
`;

const InputWrapper = styled.div`
  width: 400px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const web3 = new Web3(INFURA_URL);

const contract = new web3.eth.Contract(contractAbi, CONTRACT_ADDRESS);

const VerifyDepositsAllowance = (): JSX.Element => {
  const [address, setAddress] = useState('');
  const [validatorKeys, setValidatorKeys] = useState<number>(-1);
  const { formatMessage } = useIntl();

  const getAllowedDeposits = async (addressToVerify: string) => {
    try {
      const allowedDeposits = await contract.methods
        .allowedDeposits(addressToVerify)
        .call();
      setValidatorKeys(Number(allowedDeposits));
    } catch (error) {
      console.error('Error fetching allowed deposits:', error);
    }
  };

  const handleInputChange = async (event: Event) => {
    const inputAddress = ((event as unknown) as CustomEvent).detail
      .value as string;
    setAddress(inputAddress);

    if (inputAddress.length === 0) {
      return setValidatorKeys(-1); // when we clear the input, hide the message
    }

    if (web3.utils.isAddress(inputAddress)) {
      await getAllowedDeposits(inputAddress);
    } else {
      setValidatorKeys(0);
    }
  };

  useEffect(() => {
    const input = (document.querySelector(
      'lukso-input'
    ) as unknown) as HTMLElement;

    input.addEventListener('on-input', handleInputChange);
    return () => input.removeEventListener('on-input', handleInputChange);
  }, []);

  return (
    <Container>
      <Heading level={2} size="medium" color="blueDark" margin="none">
        <FormattedMessage defaultMessage="Check your address" />
      </Heading>
      <Text className="mt25 mb25">
        <FormattedMessage defaultMessage="Enter your address to confirm that it is whitelisted." />
      </Text>
      <InputWrapper>
        <lukso-input
          value={address}
          name="address"
          placeholder={formatMessage({ defaultMessage: 'Enter address....' })}
          is-full-width
          custom-class="paragraph-ptmono-14-regular"
        />
      </InputWrapper>
      <Message className="mt30">
        {validatorKeys === 0 ? (
          <>
            <img src={Error} alt="" />
            <FormattedMessage defaultMessage="This address is not whitelisted" />
          </>
        ) : (
          <></>
        )}
        {validatorKeys > 0 ? (
          <>
            <img src={Success} alt="" />
            <FormattedMessage
              defaultMessage="Whitelisted for a remaining of {validatorKeys} validator keys"
              values={{ validatorKeys: <b>{validatorKeys}</b> }}
            />
          </>
        ) : (
          <></>
        )}
      </Message>
    </Container>
  );
};

export default VerifyDepositsAllowance;
